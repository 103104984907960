import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import PrayerTable from "./PrayerTable";
import Http from "../helpers/Http";
import dateFormat from "dateformat";

const DailyPrayerTimes = () => {
  const d = new Date();
  const [prayerData, setPrayerData] = useState([]);
  const [currentPrayer, setCurrentPrayer] = useState({});
  const [extraPrayer, setExtraPrayer] = useState([]);
  const [eidPrayerTitle, setEidPrayerTitle] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [prayerDate, setPrayerDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  const month = [];
  month[0] = "january";
  month[1] = "february";
  month[2] = "march";
  month[3] = "april";
  month[4] = "may";
  month[5] = "june";
  month[6] = "july";
  month[7] = "august";
  month[8] = "september";
  month[9] = "october";
  month[10] = "november";
  month[11] = "december";

  useEffect(() => {
    setCurrentMonth(month[d.getMonth()]);
    console.log("currentMonth: ", currentMonth);
  }, []);

  useEffect(() => {
    try {
      Http.get("prayer")
        .then((response) => {
          setPrayerData(response.data.prayerTime);
          setCurrentPrayer(response.data.currentPrayer);
          setCurrentDate(response.data.prayerTime.date);
          setPrayerDate(response.data.currentPrayer.islamicDate);
        })
        .catch((error) => {
          console.log(error);
        });
      Http.get("extra")
        .then((response) => {
          setExtraPrayer(response.data);
          response.data.some((prayer) => {
            if (prayer.type.name === "EID") {
              return setEidPrayerTitle(true);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const arrayData = [];

  for (const [key, value] of Object.entries(prayerData)) {
    if (
      key === "fajrJamah" ||
      key === "zuhrJamah" ||
      key === "asrJamah" ||
      key === "maghribJamah" ||
      key === "ishaJamah"
    ) {
      arrayData.push(value);
    }
  }

  const convertTime = (time) => {
    return new Date(`1970-01-01T${time}Z`).toLocaleTimeString(
      {},
      {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      }
    );
  };

  function realtime() {
    let time = moment().format("h:mm:ss a");

    setCurrentTime(time);

    setInterval(() => {
      time = moment().format("h:mm:ss a");
      setCurrentTime(time);
    }, 1000);
  }

  useEffect(() => {
    realtime();
    if (currentTime === convertTime(currentPrayer.time)) {
      window.location.reload();
    }
  }, []);

  return (
    <Container>
      <Wrap>
        <Center>
          <Title>Daily Prayer Times</Title>
          <Date1>
            {dateFormat(currentDate, "mmmm dS, yyyy")} {currentTime}
          </Date1>
          <Date2>{`${moment().format("dddd")}, ${prayerDate}` || ""}</Date2>
          <Text style={{ color: "#ffffff" }}>
            {currentPrayer.prayerName ? currentPrayer.prayerName : ""}
          </Text>
          <Time>
            {currentPrayer.time ? convertTime(currentPrayer.time) : ""}
          </Time>
        </Center>
        <PrayerTable prayerData={prayerData} />
        <Center>
          <Title>Jummah Khutbah</Title>
          {extraPrayer
            ? extraPrayer.map((item, key) =>
                item.type.name !== "EID" ? (
                  <Text key={key}>
                    {item.language.name} at{" "}
                    {moment(item.prayerTime, ["h:mm:ss"]).format("HH:mm a")} by{" "}
                    {item.prayerBy}
                  </Text>
                ) : null
              )
            : ""}
          {eidPrayerTitle ? <Title>Eid</Title> : null}
          {extraPrayer
            ? extraPrayer.map((item, key) =>
                item.type.name === "EID" ? (
                  <Text key={key}>
                    {item.language.name} at{" "}
                    {moment(item.prayerTime, ["h:mm:ss"]).format("HH:mm a")} by{" "}
                    {item.prayerBy}
                  </Text>
                ) : null
              )
            : ""}
          <Text>
            Download{" "}
            <a
              href={`/downloads/${currentMonth}.pdf`}
              target="_blank"
              download
              rel="noreferrer">
              {currentMonth}
            </a>{" "}
            prayer timetable Download{" "}
          </Text>
        </Center>
      </Wrap>
    </Container>
  );
};

export default DailyPrayerTimes;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const Wrap = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const Title = styled.h3`
  font-size: 20px;
  margin: 0;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
`;

const Date1 = styled.span`
  color: #000000;
  font-weight: bold;
`;
const Date2 = styled.span`
  font-size: 14px;
  font-style: italic;
  margin: 5px 0;
`;
const Text = styled.p`
  text-align: center;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
  font-weight: bold;
`;
const Time = styled.time`
  color: #ffffff;
  font-weight: bold;
`;

const Container = styled.div`
  flex: 1;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 25%);
  background: #84ba40;
  border-radius: 4px;
  padding: 5px 2%;

  @media (max-width: 480px) {
    ${Title} {
      font-size: 30px;
    }
  }

  @media (max-width: 375px) {
    ${Title} {
      font-size: 25px;
    }
  }
`;
